<template>
  <div>
    <h1 :class="$style.pageTitle">設定</h1>
    <div class="row">
      <div class="col-lg-12">
        <div :class="$style.cardContainer">
          <section class="card">
            <div class="card-header">
              <h2>休日設定</h2>
            </div>
            <div class="card-body">
              <div v-if="holidaysLoading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <div v-if="!holidaysLoading" class="holidays">
                <a-list
                  item-layout="horizontal"
                  :data-source="holidaysList"
                  :pagination="false"
                >
                  <a-list-item slot="renderItem" slot-scope="item" :class="$style.holidaysListItem">
                    <a slot="actions"><a-button @click="deleteHoliday(item.id)" type="danger">削除</a-button></a>
                    <a-list-item-meta>
                      <div slot="title" class="list-name">
                        <p style="margin-bottom: 0;">{{ item.date }}</p>
                      </div>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </section>
          <p class="text-center" v-if="!holidaysLoading" :class="$style.buttonContainer">
            <a-button :loading="holidayButtonLoading" type="primary" @click="onMakeHoliday" :class="$style.filledBtn">
              追加
            </a-button>
          </p>
        </div>
      </div>
    </div>
    <a-modal
      title="休日の追加"
      :visible="visibleHoliday"
      @cancel="handleCancelHoliday"
    >
      <div>
        <a-form-model :model="holidayForm">
          <a-form-model-item ref="date" label="日付" prop="date">
            <a-config-provider :locale="locale">
              <a-date-picker placeholder="日付" v-model="holidayForm.date" />
            </a-config-provider>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelHoliday">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmHolidayLoading" @click="handleOkHoliday">
              追加
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  data() {
    return {
      locale: jaJa,

      holidaysLoading: true,
      holidayButtonLoading: false,
      visibleHoliday: false,
      confirmHolidayLoading: false,
      holidaysList: [],
      holidayForm: {
        date: moment(),
      },

      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    }
  },
  created() {
    const holidaysResult = Vue.prototype.$api.send('get', 'holidays')
    holidaysResult.then(response => {
      this.holidaysList = response
      this.holidaysLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 休日の取得に失敗しました。',
        })
        this.holidaysLoading = false
      })
  },
  methods: {
    handleCancelHoliday() { this.visibleHoliday = false },
    onMakeHoliday() { this.visibleHoliday = true },
    sortHolidays() {
      this.holidaysList.sort(function(a, b) {
        if (a.date < b.date) return -1
        if (a.date > b.date) return 1
        return 0
      })
    },
    handleOkHoliday() {
      const _this = this
      this.confirmHolidayLoading = true
      var actionType = 'post'
      var bodyData = { date: this.holidayForm.date.format('YYYY-MM-DD') }

      const sendHoliday = Vue.prototype.$api.send(actionType, 'holidays', bodyData)
      sendHoliday.then(response => {
        _this.$notification['success']({
          message: '休日を追加しました。',
        })
        this.holidaysList.push(response)
        this.sortHolidays()
        this.visibleHoliday = false
        this.confirmHolidayLoading = false
      })
        .catch(error => {
          if (error.status === 403) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.confirmHolidayLoading = false
          console.log(error)
        })
    },
    deleteHoliday(id) {
      const _this = this
      this.confirmHolidayLoading = true
      const send = Vue.prototype.$api.send('delete', 'holidays', { id: id })
      send.then(response => {
        _this.$notification['success']({
          message: '休日を削除しました。',
        })
        this.holidaysList = this.holidaysList.filter(e => e.id !== id)
        this.visibleHoliday = false
        this.confirmHolidayLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleHoliday = false
          this.confirmHolidayLoading = false
          console.log(error)
        })
    },
  },
}
</script>
